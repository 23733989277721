import request from "@/utils/request";

const api = {
  getSiteVideo: "/dsmcore/api/worksite/camera/video", // 查看工地视频
  stopSiteVideo: "/dsmcore/api/worksite/camera/unvideo", // 断开工地视频
  onCall: "/dsmcore/api/worksite/camera/call", // 通话请求
  offCall: "/dsmcore/api/worksite/camera/uncall", // 通话结束
  cameraMove: "/dsmcore/api/worksite/camera/move", // 移动摄像头
  cameraReset: "/dsmcore/api/worksite/camera/reset", // 摄像头复位
  cameraRestart: "/dsmcore/api/worksite/camera/restart", // 摄像头重启
  cameraStatus: "/dsmcore/api/worksite/camera/status", // 摄像头状态
  getEvent: "/dsmcore/api/worksite/event", // 查询事件
  playbackStart: "/dsmcore/api/worksite/playbackstart", // 回放请求 工地查看回放
  playbackIndex: "/dsmcore/api/worksite/playbackindex", // 工地回放索引
  Playback: "/files/api/worksite/playback", // 回放请求 工地视频获取
  videoSignal: "dsmcore/api/device/signal", //获取摄像机信号强度
  getSitPreset: "/dsmcore/api/worksite/camera/preset", //设置摄像头初始位置
  historyList: "/dsmcore/api/devicePreset/historyList", //查询预置位历史记录
  wsbinddevices: "/dsmcore/api/device/wsbinddevices", //查询工地设备信息
  setdefault: "/dsmcore/api/device/setdefault", //设置默认设备
  // GB28181设备
  GBvideostatus: "/dsmcore/api/GB28181/status", //GB28181设备状态查询
  GBvideolive: "/dsmcore/api/GB28181/live", //实时预览
  GBvideounlive: "/dsmcore/api/GB28181/unlive", //停止预览
  GBcameramove: "/dsmcore/api/GB28181/cameramove", //云台控制
  GBbroadcast: "/dsmcore/api/GB28181/broadcast", //语音文件广播
  GBintercomstart: "/dsmcore/api/GB28181/intercomstart", //开始语音对讲
  GBintercomend: "/dsmcore/api/GB28181/intercomend", //结束语音对讲
  GBplaybackquery: "/dsmcore/api/GB28181/playbackquery", //录像查询
  GBplaybackstart: "/dsmcore/api/GB28181/playbackstart", //视频点播
  GBplaybackstop: "/dsmcore/api/GB28181/playbackstop", //停止点播
  GBplaybackspeed: "/dsmcore/api/GB28181/playbackspeed", //倍速回放
  GBplaybackseek: "/dsmcore/api/GB28181/playbackseek", //回放拖动
  GBplaybackresume: "/dsmcore/api/GB28181/playbackresume", //回放恢复
  GBplaybackpause: "/dsmcore/api/GB28181/playbackpause", //回放暂停
  GBliverecord: "/dsmcore/api/GB28181/liverecord", //直播录像回调接口
};

export function GetSiteVideo(parameter) {
  return request({
    url: api.getSiteVideo,
    method: "post",
    params: parameter,
  });
  // return new Promise((resolve) => {
  //   const data = {
  //     Msg: "",
  //     Code: "200",
  //     Data: {
  //       video_url: "rtmp://39.107.116.3:1935/stream/1636874302720",
  //       com_only: "d454e0d0-7365-4e72-b856-8e2ef2408551",
  //       audio_url: "rtmp://39.107.116.3:1935/stream/1636874302713",
  //     },
  //     Code: 200,
  //   };
  //   resolve(data);
  // });
}

export function StopSiteVideo(parameter) {
  return request({
    url: api.stopSiteVideo,
    method: "post",
    params: parameter,
  });
}
export function OnCall(parameter) {
  return request({
    url: api.onCall,
    method: "post",
    params: parameter,
  });
}
export function OffCall(parameter) {
  return request({
    url: api.offCall,
    method: "post",
    params: parameter,
  });
}
export function CameraMove(parameter) {
  return request({
    url: api.cameraMove,
    method: "post",
    params: parameter,
  });
}
export function CameraReset(parameter) {
  return request({
    url: api.cameraReset,
    method: "post",
    params: parameter,
  });
}
export function CameraRestart(parameter) {
  return request({
    url: api.cameraRestart,
    method: "post",
    params: parameter,
  });
}
export function CameraStatus(parameter) {
  return request({
    url: api.cameraStatus,
    method: "post",
    params: parameter,
  });
}
// 回放
export function GetEvent(parameter) {
  return request({
    url: api.getEvent,
    method: "post",
    params: parameter,
  });
}
export function PlaybackStart(parameter) {
  return request({
    url: api.playbackStart,
    method: "post",
    params: parameter,
  });
}
export function PlaybackIndex(parameter) {
  return request({
    url: api.playbackIndex,
    method: "post",
    params: parameter,
  });
}
export function Playback(parameter) {
  return request({
    url: api.Playback,
    method: "post",
    params: parameter,
  });
}
export function videoSignal(parameter) {
  return request({
    url: api.videoSignal,
    method: "post",
    params: parameter,
  });
}
// 设置摄像头初始位置
export function getSitPreset(parameter) {
  return request({
    url: api.getSitPreset,
    method: "post",
    params: parameter,
  });
}

export function GethistoryList(parameter) {
  return request({
    url: api.historyList,
    method: "post",
    params: parameter,
  });
}
// 查询工地绑定设备信息
export function GetWsbinddevices(parameter) {
  return request({
    url: api.wsbinddevices,
    method: "post",
    params: parameter,
  });
}
// 设置默认设备
export function Setdefault(parameter) {
  return request({
    url: api.setdefault,
    method: "post",
    params: parameter,
  });
}
// GB28181设备状态查询
export function GBVideoStatus(parameter) {
  return request({
    url: api.GBvideostatus,
    method: "post",
    params: parameter,
  });
}
// GB28181设备实时预览
export function GBVideoLive(parameter) {
  return request({
    url: api.GBvideolive,
    method: "post",
    params: parameter,
  });
}
// GB28181设备停止预览
export function GBVideoUnlive(parameter) {
  return request({
    url: api.GBvideounlive,
    method: "post",
    params: parameter,
  });
}
// GBcameramove;GB28181设备云台控制
export function GBCameraMove(parameter) {
  return request({
    url: api.GBcameramove,
    method: "post",
    params: parameter,
  });
}
// GBbroadcast GB28181设备  语音文件广播
export function GBBroadCast(parameter) {
  return request({
    url: api.GBbroadcast,
    method: "post",
    params: parameter,
  });
}
// GBintercomstart GB28181设备  开始语音对讲
export function GBInterComstart(parameter) {
  return request({
    url: api.GBintercomstart,
    method: "post",
    params: parameter,
    // headers: { "content-type": "application/x-www-form-urlencoded" },
  });
}
// GBintercomend GB28181设备  结束语音对讲
export function GBInterComend(parameter) {
  return request({
    url: api.GBintercomend,
    method: "post",
    params: parameter,
    // headers: { "content-type": "application/x-www-form-urlencoded" },
  });
}
// GBplaybackquery GB28181设备  录像查询
export function GBPlaybackQuery(parameter) {
  return request({
    url: api.GBplaybackquery,
    method: "post",
    params: parameter,
  });
}
// GBplaybackstart GB28181设备  视频点播
export function GBPlaybackStart(parameter) {
  return request({
    url: api.GBplaybackstart,
    method: "post",
    params: parameter,
  });
}
// GBplaybackstop GB28181设备  停止点播
export function GBPlaybackStop(parameter) {
  return request({
    url: api.GBplaybackstop,
    method: "post",
    params: parameter,
  });
}
// GBplaybackspeed GB28181设备  倍速回放
export function GBPlaybackSpeed(parameter) {
  return request({
    url: api.GBplaybackspeed,
    method: "post",
    params: parameter,
  });
}
// GBplaybackseek GB28181设备  回放拖动
export function GBPlaybackSeek(parameter) {
  return request({
    url: api.GBplaybackseek,
    method: "post",
    params: parameter,
  });
}
// GBplaybackresume GB28181设备  回放恢复
export function GBPlaybackResume(parameter) {
  return request({
    url: api.GBplaybackresume,
    method: "post",
    params: parameter,
  });
}
// GBplaybackpause GB28181设备  回放暂停
export function GBPlaybackPause(parameter) {
  return request({
    url: api.GBplaybackpause,
    method: "post",
    params: parameter,
  });
}
// GBliverecord GB28181设备  直播录像回调接口
export function GBLiveRecord(parameter) {
  return request({
    url: api.GBliverecord,
    method: "post",
    params: parameter,
  });
}
