import request from "@/utils/request";

const api = {
  getSiteMap: "/dsmcore/api/worksite/mapwork", // 查询施工地图
  // getSiteMap: "/dsmcore/api/worksite/mapwork", // 查询施工地图
  getSiteList: "/dsmcore/api/worksite/worklist", // 查询工地列表
  getSnapshot: "/dsmcore/api/worksite/snapshot", // 查询某天某工地所有的快照信息
  getAttend: "/dsmcore/api/worksite/attendance", // 查询此工地的考勤信息
  getSiteCalendar: "/dsmcore/api/worksite/calendar", // 查询工地的施工日历
  stopWork: "/dsmcore/api/worksite/stopwork", // 暂停施工
  recoveryWork: "/dsmcore/api/worksite/recoverywork", // 恢复施工
  addSite: "/dsmcore/api/worksite/addworksite", // 录入工地信息
  updateSite: "/dsmcore/api/worksite/updateworksite", // 修改工地信息
  setSampleSite: "/dsmcore/api/worksite/model", // 设置样板工地
  cancelSampleSite: "", // 取消样板工地
  sitDetail: "/dsmcore/api/worksite/detail", //工地详细信息
  sitPeopledetail: "/dsmcore/api/worksite/peopledetail", //工地项目成员
  siteDevicedetail: "/dsmcore/api/worksite/devicedetail", // 工地设备记录
  getNameList: "/dsmcore/api/worksite/namelist", //巡查页获取工地名称列表
  mapsitedetail: "/dsmcore/api/worksite/mapsitedetail", // 地图根据工地id获取信息
  delSiteinfo: "/dsmcore/api/worksite/delws", //删除工地信息
  devicebind: "/dsmcore/api/qzw/devicebind", //设备绑定
  deviceunbound: "/dsmcore/api/qzw/deviceunbound", //设备解绑
};

export function GetSiteMap(parameter) {
  return request({
    url: api.getSiteMap,
    method: "post",
    params: parameter,
  });
}
export function GetServiceList(parameter) {
  return request({
    url: api.getSiteList,
    method: "post",
    params: parameter,
  });
}

export function GetSnapshot(parameter) {
  return request({
    url: api.getSnapshot,
    method: "post",
    params: parameter,
  });
}

export function GetAttend(parameter) {
  return request({
    url: api.getAttend,
    method: "post",
    params: parameter,
  });
}
export function GetSiteCalendar(parameter) {
  return request({
    url: api.getSiteCalendar,
    method: "post",
    params: parameter,
  });
}
export function StopWork(parameter) {
  return request({
    url: api.stopWork,
    method: "post",
    params: parameter,
  });
}

export function RecoveryWork(parameter) {
  // return request({
  //   url: api.recoveryWork,
  //   method: "post",
  //   params: parameter,
  // });
  return new Promise((resolve) => {
    const data = {
      message: "",
      data: {},
      status: 200,
    };
    resolve(data);
  });
}

export function AddSite(parameter) {
  return request({
    url: api.addSite,
    method: "post",
    params: parameter,
  });
}

export function UpdateSite(parameter) {
  return request({
    url: api.updateSite,
    method: "post",
    params: parameter,
  });
}

export function SetSampleSite(parameter) {
  return request({
    url: api.setSampleSite,
    method: "post",
    params: parameter,
  });
}
export function CancelSampleSite(parameter) {
  return request({
    url: api.cancelSampleSite,
    method: "post",
    params: parameter,
  });
}
// 工地详细信息
export function sitDetail(parameter) {
  return request({
    url: api.sitDetail,
    method: "post",
    params: parameter,
  });
}
// 工地项目成员
export function sitPeopledetail(parameter) {
  return request({
    url: api.sitPeopledetail,
    method: "post",
    params: parameter,
  });
}
// 工地设备记录
export function siteDevicedetail(parameter) {
  return request({
    url: api.siteDevicedetail,
    method: "post",
    params: parameter,
  });
}
export function GetNameList(parameter) {
  return request({
    url: api.getNameList,
    method: "post",
    params: parameter,
  });
}

export function Getmapsitedetail(parameter) {
  return request({
    url: api.mapsitedetail,
    method: "post",
    params: parameter,
  });
}
//删除工地信息
export function DelSiteinfo(parameter) {
  return request({
    url: api.delSiteinfo,
    method: "post",
    params: parameter,
  });
}
//设备绑定
export function DeviceBind(parameter) {
  return request({
    url: api.devicebind,
    method: "post",
    params: parameter,
  });
}
//设备解绑
export function DeviceUnBound(parameter) {
  return request({
    url: api.deviceunbound,
    method: "post",
    params: parameter,
  });
}
